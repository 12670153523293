/* About */

.about-title {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}

.about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;
}

.about .description {
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 25px 25px;
}

.about .text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 6px 6%;
    margin: 0 20%;
}

.my-photo {
    margin: 10px 10px;
    padding: 10px 10px;
}

.about img{
    border-radius: 50%;

}

.tecno-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 5%;
}

.tecno-list li{
    margin: 6px 6px;
    padding: 10px 10px;
    color: lightslategrey;
    font-weight: 600;
}


@media (max-width: 1024px) {
    .about .text {
        margin: 0 10%;
    }
}

@media (max-width: 575px) {
    .about .text {
        margin: 0 0%;
    }
}