* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.anchor{
  padding-top: 65px;
  margin-top: -65px;
  display: inline-block; /* required for webkit browsers */
}

/* Banner e Intro */

.banner {
  /* background: linear-gradient(#f5f5f53b, #2b2b2b5b, #5f5f5f5b), url('./banner-img.jpg'); */
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url('./banner-img.jpg') center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: beige;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner h1{
  font-size: 3.5rem;
  text-shadow: dimgray 1px 1px;
  padding: 6px 10px;
  margin: 0 16px;
}

.banner h2 {
  font-size: 2.0rem;
  text-shadow: dimgray 1px 1px;
  padding: 6px 10px;
  margin: 0 16px;
}

.intro-text {
  font-size: 1.15rem;
  padding: 6px 10px;
  margin: 0 16px;
  text-shadow: dimgray 1px 1px;
}

.section-title {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  /* width: 100%;
  height: 90vh; */
}

@media (max-width: 650px) {
  .banner {
      align-items: flex-start;
  }
}

/* Search */

.filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 20px 10px 20px;
}

input {
  padding: 10px;
  width: 40%;
}

.filter button{
  margin: 6px 6px;
  padding: 10px 10px;
  cursor: pointer;
}

/* Portfolio Cards */

.cards-tab {
  display: flex;
  align-items: flex-start;
  justify-content:center;
  flex-wrap: wrap;
  padding: 0 16px;
}

.cards-tab ul{
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.cards-tab ul li{
  padding: 6px 6px;
}

.load-more-works {
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more-works button{
  margin: 6px 6px;
  padding: 10px 10px;
  cursor: pointer;
}