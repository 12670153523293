header {
}

header nav {
    display: flex;
    align-items: center;
    /* justify-content: start; */
    background-color: #f8f9fa;
    padding: 10px 10px;
    position: fixed;
    right: 0;
    left: 0;
}

header nav ul{
    display: flex;
    align-items: center;
    list-style: none;
}

header nav ul li{
    padding: 10px 10px;
}

header a{
    color:dimgray;
}

li a{
    color: dimgray;
}

h2 {
    padding-right: 10px;
}

