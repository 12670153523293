
footer {
    background-color: dimgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #f5f5f5;
    padding: 20px 20px;
  }
  
  footer ul{
    display: flex;
    list-style: none;
  }
  
  footer ul li{
    padding: 10px 10px;
  }
  
  footer li a{
    font-size: 2.3rem;
    color: #f5f5f5;
  }
  
  footer a:visited{
    color: #f5f5f5;
  }
  
  footer svg:hover {
    color:lightseagreen;
  }