.card-box {
    width: 33%;
    padding: 8px 16px;
    /* max-width: 300px; */
}

.card-box img{
    width: 100%;
    flex-grow: 1;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    padding: 4px 0px;
}

.card-description {

}

.card-box ul li{
    color: lightslategrey;
    font-weight: 600;
}

@media (max-width: 1024px) {
    .card-box {
        width: 50%;
    }
}

@media (max-width: 550px) {
    .card-box {
        width: 90%;
    }
}